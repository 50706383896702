.chat-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.header-container {
  text-align: center;
  background-color: #6ab2d0;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  color: white;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

h1 {
  margin: 0;
  font-size: 24px;
}

.messages-container {
  background-color: white;
  padding: 15px;
  border-radius: 0 0 10px 10px;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.user-text {
  background-color: #d1e7dd;
  border-radius: 10px 10px 0 10px;
  padding: 10px;
  margin-left: auto;
  max-width: 70%;
  text-align: right;
}

.bot-message {
  display: flex;
  align-items: flex-start;
  max-width: 70%;
}

.chatbot-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.bot-text {
  background-color: #f8d7da;
  border-radius: 10px 10px 10px 0;
  padding: 10px;
}

.input-container {
  display: flex;
  margin-top: 15px;
}

.input {
  flex: 1;
  padding: 10px;
  border: 2px solid #6ab2d0;
  border-radius: 5px 0 0 5px;
  outline: none;
  font-size: 16px;
}

.ask-button {
  padding: 10px 20px;
  background-color: #6ab2d0;
  border: none;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;
}

.ask-button:hover {
  background-color: #5a9ab2;
}

